import React, { useState, useRef } from "react";

export default function MyComponent() {
  const [activeLink, setActiveLink] = useState(null);
  const link1Ref = useRef(null);
  const link2Ref = useRef(null);
  const link3Ref = useRef(null);
  const link4Ref = useRef(null);
  const link5Ref = useRef(null);

  const handleClick = (event) => {
    setActiveLink(event.currentTarget);
  };
  return (
    <div className="sbn-list">
      <div className="sbn sbn11">
        <ul>
          <li>
            <a
              href="./#FirstPage"
              onClick={handleClick}
              ref={link1Ref}
              className={activeLink === link1Ref.current ? "is-active" : ""}
            >
              1
            </a>
          </li>
          <li>
            <a
              href="./#SecondPage"
              onClick={handleClick}
              ref={link2Ref}
              className={activeLink === link2Ref.current ? "is-active" : ""}
            >
              2
            </a>
          </li>
          <li>
            <a
              href="./#universe"
              onClick={handleClick}
              ref={link3Ref}
              className={activeLink === link3Ref.current ? "is-active" : ""}
            >
              3
            </a>
          </li>
          <li>
            <a
              href="./#ForthPage"
              onClick={handleClick}
              ref={link4Ref}
              className={activeLink === link4Ref.current ? "is-active" : ""}
            >
              4
            </a>
          </li>
          <li>
            <a
              href="./#Footer"
              onClick={handleClick}
              ref={link5Ref}
              className={activeLink === link5Ref.current ? "is-active" : ""}
            >
              5
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
