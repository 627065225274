import React from "react";
import { Icon } from "@iconify/react";

export default function Technologies() {
  return (
    <div className="ThirdPage" id="universe">
      <h3 className="boujee">My universe</h3>
      <h4>I build my projects with</h4>{" "}
      <div className="technologies">
        <p>
          <Icon icon="la:bootstrap" color="#05ac76" />
        </p>
        <p>
          {" "}
          <Icon icon="mdi:responsive" color="#05ac76" />
        </p>{" "}
        <p>
          <Icon icon="akar-icons:javascript-fill" color="#05ac76" />
        </p>
        <p>
          {" "}
          <Icon icon="akar-icons:react-fill" color="#05ac76" />
        </p>
        <br />
        <p>
          <Icon icon="mdi:microsoft-visual-studio-code" color="#05ac76" />
        </p>{" "}
        <p>
          <Icon icon="radix-icons:github-logo" color="#05ac76" />
        </p>{" "}
        <p>
          {" "}
          <Icon icon="simple-icons:macos" color="#05ac76" />
        </p>
      </div>
    </div>
  );
}
