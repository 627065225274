import React from "react";
import "./Footer.css";
import cv from "./CV_leiladaraei.pdf";
import { Icon } from "@iconify/react";

export default function Footer() {
  return (
    <div className="Footer" id="Footer">
      <div className="myworksection">
        <a href={cv} className="myworks" target="_blank" rel="noreferrer">
          {" "}
          My Résumé
        </a>
        <a
          href="https://github.com/LeilaDaraei"
          target="_blank"
          rel="noreferrer"
          className="myworks"
        >
          {" "}
          Source Code
        </a>
      </div>

      <div className="github">
        {" "}
        <a
          href="https://github.com/LeilaDaraei"
          target="_blank"
          rel="noreferrer"
          className="githubicon"
        >
          {" "}
          <Icon icon="radix-icons:github-logo" color="#05ac76" />
        </a>
        <a
          href="https://www.linkedin.com/in/leiladaraei"
          target="_blank"
          rel="noreferrer"
          className="linkedinicon"
        >
          <Icon icon="uiw:linkedin" color="#8a67f2" />
        </a>
      </div>
    </div>
  );
}
