import React from "react";
import "./ForthPage.css";
import { Icon } from "@iconify/react";

export default function ForthPage() {
  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "4dac0883-a2c8-4ae0-83a4-757246c50805");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      console.log("Success", res);
    }
  };

  return (
    <div className="ForthPage row" id="ForthPage">
      <div className="col-7 pt-5 mt-5">
        <h3 className="boujee">Contact me</h3>
        <p className="mt-5 mb-5">
          Got a question or proposal, or just want to say hello? Go ahead and
          leave me a message !
        </p>
        <p>
          {" "}
          <span className="me-2 mailicon">
            <Icon
              icon="material-symbols:outgoing-mail-outline"
              color="#8a67f2"
            />
          </span>{" "}
          <a
            href="mailto:leila@daraei.me"
            target="_blank"
            rel="noreferrer"
            className="emaillink"
          >
            leila@daraei.me
          </a>
        </p>
      </div>
      <div className="col-5 ps-5">
        <form onSubmit={onSubmit}>
          <div className="mb-5">
            <label>Your Name: </label>
            <input
              type="text"
              className="w-100 rounded form-control mt-2"
              name="name"
              required
            />
          </div>
          <div className="mb-5">
            <label>Email Adress: </label>
            <input
              type="email"
              name="email"
              className="w-100 rounded form-control mt-2"
            />
          </div>
          <div className="mb-5">
            <label>Your Message: </label>
            <textarea
              name="message"
              className="form-control mt-2"
              rows="5"
            ></textarea>
          </div>
          <button className="hitmeup rounded" type="submit">
            <span className="hit">⋙ Hit me up</span>
          </button>
        </form>
      </div>
    </div>
  );
}
