import React from "react";
import "./SecondPage.css";
import Typewriter from "typewriter-effect";
import ai from "./ai.jpg";

export default function SecondPage() {
  return (
    <div className="SecondPage" id="SecondPage">
      <div className="row mt-5 mb-5 d-flex justify-content-between">
        <div className="col-6">
          <h3 className="boujee">About me</h3>
          <h4>
            <Typewriter
              options={{
                strings: [
                  "Developer",
                  "Problem Solver",
                  "Globetrotter",
                  "Chocoholic",
                  "Creator",
                  "Aesthete",
                ],
                autoStart: true,
                loop: true,
                cursor: "",
                pauseFor: 1000,
              }}
            />
          </h4>
          <p>
            I am Leila, a passionate &#123; Reactjs &#125; developer. I have
            been enjoying the world of design and coding for the past year and
            am always looking for new challenges and opportunities to grow as a
            developer.
          </p>
        </div>
        <div className="col-4">
          <img src={ai} alt="Leila Daraei" className="img-fluid" />
        </div>
      </div>
    </div>
  );
}
