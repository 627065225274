import "./App.css";
import FirstPage from "./FirstPage";
import SecondPage from "./SecondPage";
import ThirdPage from "./ThirdPage";
import ForthPage from "./ForthPage";
import Footer from "./Footer";
import Links from "./Links";

export default function App() {
  return (
    <div className="App">
      <header className="App-header">
        <FirstPage />
        <SecondPage />
        <ThirdPage />
        <ForthPage />
        <Footer />
        <Links />
      </header>
    </div>
  );
}
