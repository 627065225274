import React from "react";
import dictionary from "./dictionary.png";
import travel from "./travel.png";
import weather from "./weather-react.png";

export default function ProjectPics() {
  return (
    <div className="ThirdPage" id="universe">
      <h3 className="boujee">My universe</h3>
      <h4>My last projects</h4>
      <div className="row images">
        <div className="col-3">
          <a
            href="https://diction-ary.netlify.app/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={dictionary}
              alt="Dictionary App"
              className="img-fluid rounded"
            />
          </a>
        </div>
        <div className="col-6">
          <a
            href="https://global-weather-react.netlify.app/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={weather}
              alt="Weather App"
              className="img-fluid rounded"
            />
          </a>
        </div>{" "}
        <div className="col-3">
          <a
            href="https://travelhamburg.netlify.app/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={travel} alt="Travel App" className="img-fluid rounded" />
          </a>
        </div>
      </div>
    </div>
  );
}
