import React, { useState } from "react";
import "./ThirdPage.css";
import Technologies from "./Technologies";
import ProjectPics from "./ProjectPics";

export default function ThirdPage() {
  let [content, setContent] = useState("projects");

  function showProjects(event) {
    event.preventDefault();
    return setContent("projects");
  }
  function showTechnologies(event) {
    event.preventDefault();
    return setContent("technologies");
  }

  if (content === "projects") {
    return (
      <div>
        <ProjectPics />{" "}
        <div className="links">
          <a href="/" className="linkss" onClick={showProjects}>
            Projects
          </a>
          <a href="/" className="linkss" onClick={showTechnologies}>
            Technologies & Tools
          </a>{" "}
        </div>
      </div>
    );
  } else if (content === "technologies") {
    return (
      <div>
        <Technologies />{" "}
        <div className="links">
          <a href="/" className="linkss" onClick={showProjects}>
            Projects
          </a>
          <a href="/" className="linkss" onClick={showTechnologies}>
            Technologies & Tools
          </a>{" "}
        </div>
      </div>
    );
  }
}
