import React from "react";
import "./FirstPage.css";

export default function FirstPage() {
  return (
    <div className="FirstPage" id="FirstPage">
      <div className="welcomeText">
        <h1>
          Hi, I am <span className="boujee">Leila Daraei</span>
        </h1>
        <h2>Junior front-end developer, based in Hamburg, DE</h2>{" "}
      </div>{" "}
      <button
        className="myuniverse rounded"
        onClick={() => window.location.replace("./#universe")}
      >
        <span className="text ">Dig into my universe</span>
      </button>
    </div>
  );
}
